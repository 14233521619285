<template>
  <div class="main-view-padding has-header">
      <div class="header main" v-header-position-lock>
          <div class="align-left">
          </div>
          <div class="align-middle">
                <headerbuttonstack :state="headerButtons"/>
          </div>
          <div class="align-right">
          </div>
      </div>
    <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
      Employees
    </div>




    <div style="overflow-y:auto; height: 800px;">
      <table class="table">
        <tr>
          <td>id</td>
          <td>Default Store</td>
          <td>Auth UUID</td>
          <td>firstName</td>
          <td>lastName</td>
        </tr>
        <tr v-for="employee in employees" v-bind:key="'e'+employee.id">
          <td>{{employee.id}}</td>
          <td>{{employee.defaultStoreId}}</td>
          <td>{{employee.accountId}}</td>
          <td>{{employee.firstName}}</td>
          <td>{{employee.lastName}}</td>
        </tr>
      </table>
    </div>
  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import button from '../uicomponents/button.vue';
export default {
 components: { button },
  name: 'superadminEmployee',
  data () {
    return {
      employees: [],
    }
  },
  created: function () {
    //console.log("CREATED");
  },
  mounted: function () {
    if(this.$store.state.jwt != undefined && this.$store.state.companyId != undefined){
        this.refresh();
    }
  },
  computed: {
        ...mapGetters(['companyId', 'isSuperUser']),
        dateDisplay: function() {
          return this.$helpers.getMomentTZ().format('YYYY-MM-DD');
        },
          headerButtons() {
              let view = this;
              let state= { buttons: [] };
              /*
              state.buttons.push({ type: "block", icon: "fa-eye", click: () => { }, tooltip: 'VIEW' });
              state.buttons.push({ type: "block", icon: "fa-cloud-download-alt", click: () => { view.loadExternalUsersFromKundencenter(); }, tooltip: 'SYNC' });
              */
              return state;
          },
        timeZoneList() {
            let result = [];
            /*
            this.timeZones.forEach(i => {
                let item = {label: i, value: i};
                result.push(item);
            })
            */
            return result;
        },
  },
  watch: {
    companyId: function(val, oldVal) {
      this.refresh();
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function(force = false){
        let view = this;
        view.loading = true;
        view.loadEmployees();
    },

    loadEmployees: function(force = false){
      let view = this;
      let url = '/api/superuser/employee';
      
      this.axios({
        method: 'get',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
      }).then(response => {
        view.employees = response.data.data;
      }).catch(function (error) {
        console.log(error);
      })
    }
  },
}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>

.xColumn3{
  width: 400px; 
  text-align:left; 
  float:left; 
  margin-left:25px; 
  margin-right:25px;
  overflow-y: auto;
  max-height: 800px;
}

</style>
